import React, { useEffect, useState } from "react";
import "./styles/favTour.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useFav } from "../context/FavListContextProvider";
import Aos from "aos";
import { getToursCountInFav } from "../helper/function";
import { BsFillChatHeartFill } from "react-icons/bs";
import FavToursCards from "./FavToursCards";
import { Helmet } from "react-helmet-async";

const FavTour = () => {
  const { fav, deleteTourFromFav } = useFav();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    Aos.init({
      duration: 500,
      once: true,
    });
  }, []);

  const [badgeCount, setBadgeCount] = React.useState();
  React.useEffect(() => {
    setBadgeCount(getToursCountInFav());
  }, [fav]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const [selectedCountry, setSelectedCountry] = useState(
    searchParams.get("country") || null
  );
  const [selectedRegion, setSelectedRegion] = useState(
    searchParams.get("region") || null
  );

  const [priceFrom, setPriceFrom] = useState(
    searchParams.get("priceFrom") || ""
  );
  const [priceUntil, setPriceUntil] = useState(
    searchParams.get("priceUntil") || ""
  );
  const search = searchParams.get("q") || "";

  useEffect(() => {
    const params = new URLSearchParams();

    if (selectedCountry) params.set("country", selectedCountry);
    if (selectedRegion) params.set("region", selectedRegion);
    if (priceFrom) params.set("priceFrom", priceFrom);
    if (priceUntil) params.set("priceUntil", priceUntil);
    if (search) params.set("q", search);

    setSearchParams(params);
  }, [
    selectedCountry,
    selectedRegion,
    priceFrom,
    priceUntil,
    search,
    setSearchParams,
    searchParams,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uniqueCountries = [
    ...new Set(fav.tours.map((tour) => tour.item.region.country.country)),
  ];

  const filteredTours = fav.tours.filter((tour) => {
    const matchesCountry =
      !selectedCountry || tour.item.region.country.country === selectedCountry;
    const matchesRegion =
      !selectedRegion || tour.item.region.region === selectedRegion;
    const matchesSearch = tour.item.name
      .toLowerCase()
      .includes(search.toLowerCase());

    const matchesPrice =
      (!priceFrom || tour.item.price >= parseFloat(priceFrom)) &&
      (!priceUntil || tour.item.price <= parseFloat(priceUntil));

    return matchesCountry && matchesRegion && matchesSearch && matchesPrice;
  });

  const handleRegionClick = (region) => {
    setSelectedRegion(region);
  };

  const currentData = () => {
    return filteredTours;
  };

  const [activeIndexes, setActiveIndexes] = useState([]);

  const toggleMenu = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const hasTourPrice = (tours) => {
    return fav.tours.some(
      (tour) => typeof tour.price === "string" && tour.price !== null
    );
  };

  const isPriceTourAvailable = hasTourPrice(fav.tours);

  return (
    <div className="fav-list-section">
      <Helmet>
        <title>Избранные туры | Ваш надежный партнер для путешествий</title>
        <meta
          name="description"
          content="Посмотрите ваши любимые туры и путешествия, которые вы добавили в список избранного. Легко управляйте своими предпочтениями и выбирайте лучший тур."
        />
        <meta
          name="keywords"
          content="избранные туры, путешествия, туры, любимые туры, туры по Казахстану, туры по Кыргызстану"
        />
      </Helmet>
      <div className="show-background-picture favBgPic">
        Избранное
        <span className="count-of-tour-in-fav">{badgeCount}</span>
      </div>
      <div className="tour-section-divider-to-two">
        <div className="filter-for-tours-lol">
          <h3>Фильтр</h3>
          <div className="line-for-filter"></div>
          <div className="category-filter-lol">
            <h4 className="category-filter-lol-title">Категории:</h4>
            <button
              className={`country-filter all-button ${
                selectedCountry === null ? "selected" : ""
              }`}
              onClick={() => {
                setSelectedCountry(null);
                setSelectedRegion(null);
                setActiveIndexes([]);
              }}
            >
              Все
            </button>
            {uniqueCountries.map((country, index) => {
              const regionsForCountry = fav.tours
                .filter((tour) => tour.item.region.country.country === country)
                .map((tour) => tour.item.region.region);
              const uniqueRegions = [...new Set(regionsForCountry)];

              return (
                <div className="country-region-container" key={country}>
                  <button
                    className={`button ${
                      selectedCountry === country ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedCountry(country);
                      toggleMenu(index);
                    }}
                  >
                    {country}
                    <span
                      className={`arrow ${
                        activeIndexes.includes(index) ? "up" : "down"
                      }`}
                    ></span>
                  </button>
                  <ul
                    className="menu"
                    style={{
                      maxHeight: activeIndexes.includes(index)
                        ? `${uniqueRegions.length * 40}px`
                        : "0",
                      transition: "max-height 0.5s ease-in-out",
                    }}
                    aria-hidden={!activeIndexes.includes(index)}
                  >
                    {uniqueRegions.map((region) => (
                      <li
                        key={region}
                        onClick={() => handleRegionClick(region)}
                        className={`menu-item ${
                          selectedRegion === region ? "selected" : ""
                        }`}
                      >
                        <span className="text-regions">{region}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
          {isPriceTourAvailable ? (
            <div className="price--filter-asap">
              <span className="category-filter-lol-title price-title-for-filter">
                Цена:
              </span>
              <div className="inp-price-for-filter">
                <input
                  type="number"
                  placeholder="Цена от"
                  value={priceFrom}
                  onChange={(e) => setPriceFrom(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Цена до"
                  value={priceUntil}
                  onChange={(e) => setPriceUntil(e.target.value)}
                />
              </div>
            </div>
          ) : null}
        </div>
        {currentData().length > 0 ? (
          <div className="fav-cards-itself-section">
            {currentData().map((elem) => (
              <FavToursCards
                key={elem.id}
                elem={elem}
                truncateText={truncateText}
                deleteTourFromFav={deleteTourFromFav}
                navigate={navigate}
              />
            ))}
          </div>
        ) : (
          <div className="if-there-is-no-fav-tour-in">
            <div className="if-there-is-no-fav-tour-in-itself">
              <BsFillChatHeartFill className="if-there-is-no-fav-tour-in-icon" />
              <span>В избранном пусто</span>
              <span>Добавляйте товары с помощью ❤️</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FavTour;
