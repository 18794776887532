import React, { useEffect, useState } from "react";
import "../Auth/Author.css";
import { Link, useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { SlEnvolopeLetter } from "react-icons/sl";
import { useAuth } from "../context/AuthContextProvider";
import Notification from "../components/Notification";

const Register = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // State for notification
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  // Registration-related state
  const { handleRegister, checkCode, isSuccess, setIsSuccess, errorReg } =
    useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [code, setCode] = useState("");

  // Navigation
  const navigate = useNavigate();

  // Timer for notification
  const openOrderModal = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };

  // Handle registration
  const handleSave = (event) => {
    event.preventDefault();
    if (!email.trim() || !password.trim() || !passwordConfirm.trim()) {
      setNotificationMessage("Заполните данные!");
      openOrderModal();
      return;
    }

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("password_confirm", passwordConfirm);
    handleRegister(formData);
  };

  // Handle activation code
  const handleCode = () => {
    if (!code.trim()) {
      setNotificationMessage("Заполните данные!");
      openOrderModal();
      return;
    }

    let formData = new FormData();
    formData.append("email", email);
    formData.append("activation_code", code);
    checkCode(formData);
  };

  return (
    <div className="auth-main">
      <div className="auth-switch__container" id="switch-c1">
        <h2 className="auth-switch__title auth-title">Добро пожаловать!</h2>
        <p className="auth-switch__description auth-description">
          Чтобы оставаться на связи с нами, пожалуйста, войдите с помощью своей
          личной информации
        </p>
        <button
          onClick={() => navigate("/login")}
          className="auth-switch__button auth-button auth-switch-btn"
        >
          ВОЙТИ
        </button>
      </div>
      <div className="auth-container auth-a-container" id="a-container">
        <form className="auth-form" id="a-form">
          <h2 className="auth-form_title auth-title">Создать аккаунт</h2>
          <span className="auth-form__span">
            или используйте электронную почту для регистрации
          </span>
          <input
            className="auth-form__input"
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Эл. почта"
          />
          <input
            className="auth-form__input"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Пароль"
          />
          <input
            className="auth-form__input"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            type="password"
            placeholder="Повторите пароль"
          />
          <div className="btn-div-for-moving-to-opposite">
            <span className="errors-related-to-reg">
              {errorReg && JSON.stringify(errorReg)}
            </span>
            <span className="phone-ask-ab-account">
              У вас уже есть аккаунт?
              <Link to={"/login"}>'войдите в него'</Link>
            </span>
            <button
              className="auth-form__button auth-button submit"
              onClick={handleSave}
            >
              СОЗДАТЬ
            </button>
            {notificationVisible && (
              <Notification
                visible={notificationVisible}
                message={notificationMessage}
              />
            )}
          </div>
        </form>
      </div>

      {isSuccess && (
        <div className="activation-code-area">
          <IoClose onClick={() => setIsSuccess(false)} className="close-icon" />
          <SlEnvolopeLetter className="letter-for-activation-code" />
          <span className="introduction-to-activation-code-part">
            Подтвердите почту
          </span>
          <h4 className="main-text-of-activation-code-part">
            Мы отправили верификационный код на вашу почту {email}.
            <br /> Пожалуйста, подтвердите, что это вы, и введите код ниже,
            состоящий из 6 символов.
          </h4>
          <input
            type="text"
            onChange={(e) => setCode(e.target.value)}
            placeholder="Введите шестизначный код"
            className="inp-of-activation-code"
          />
          <button className="btn-confirm-and-send" onClick={handleCode}>
            Подтвердить
          </button>
          <span className="activation-code-error">
            Не получили код?
            <Link
              onClick={() => window.location.reload()}
              className="send-user-to-reg-again"
            >
              Попробуйте еще раз
            </Link>
          </span>
        </div>
      )}
    </div>
  );
};

export default Register;
