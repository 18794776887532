/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa6";
import "./style/footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useProduct } from "../context/ProductContextProvider";

const Footer = () => {
  const navigate = useNavigate();
  const { tours, getTours } = useProduct();

  useEffect(() => {
    getTours();
  }, [getTours]);
  const activeTours = tours.filter((tour) => tour.is_active);
  const isKazakhstan = hasKazakhstanTours(activeTours);

  const goToTours = (country) => {
    navigate(`/tours?country=${country}`);
  };
  return (
    <div className="footer_container">
      <div className="footer_logo">
        <span>
          Nomad
          <br />
          Journey
        </span>
        <marquee behavior="scroll" direction="left">
          Ваш гид по Кыргызстану!
        </marquee>
      </div>
      <div className="footer_links">
        <span>Информация</span>
        <ul>
          <li>
            <a
              href="#"
              onClick={() => goToTours("Кыргызстан")}
              style={{ cursor: "pointer" }}
            >
              Туры в Кыргызстан
            </a>
          </li>
          {isKazakhstan ? (
            <li>
              <a
                href="#"
                style={{ cursor: "pointer" }}
                onClick={() => goToTours("Казахстан")}
              >
                Туры в Казахстан
              </a>
            </li>
          ) : null}
          <li>
            <Link to={"/tours"}>Чем заняться</Link>
          </li>
          <li>
            <Link to={"/tours"}>Интересные места</Link>
          </li>
        </ul>
      </div>
      <div className="footer_links">
        <span>О нас</span>
        <ul>
          <li>
            <Link to={"/about"}>О компании</Link>
          </li>
          <li>
            <Link to={"/tours"}>Туры</Link>
          </li>

          <li>
            <Link to={"/about"}>Поддержка</Link>
          </li>
        </ul>
      </div>
      <div className="footer_links">
        <span>Контакты</span>
        <ul>
          <li>
            <a href="tel:996550555508">+996 550 555 508</a>
          </li>
          <li>
            <a href="tel:996709555508">+996 709 555 508</a>
          </li>
          <li>
            <a href="mailto:kgkztours@gmail.com">kgkztours@gmail.com</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/267+%D0%A8%D0%B0%D0%BF%D0%B0%D0%BA+%D0%91%D0%B0%D0%B0%D1%82%D1%8B%D1%80,+%D0%9A%D0%B0%D1%80%D0%B0%D0%BA%D0%BE%D0%BB/@42.4840815,78.3802306,20z/data=!4m5!3m4!1s0x38865c74370588e7:0x738a9f1cebb828b0!8m2!3d42.4841129!4d78.3804251?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
              rel="noreferrer"
            >
              Республика Кыргызстан, <br /> Иссык-Кульская область
              <br /> город Каракол, <br /> ул Шапак-Батыра 267
            </a>
          </li>
          <li>
            <p>
              Сделано командой{" "}
              <a href="https://www.instagram.com/991o2o9/" target="__blank">
                991o2o9
              </a>
            </p>
          </li>
        </ul>
      </div>
      <div className="footer_links">
        <span>Соц. сети</span>
        <div className="footer_social_item">
          <a
            href="https://wa.me/996550555508"
            target="_blank"
            className="social_media_sender whats"
          >
            <FaWhatsapp />
          </a>
          <a
            href="https://t.me/BadBoyHunter1984"
            target="_blank"
            className="social_media_sender teleg"
          >
            <FaTelegram className="social_media_sender teleg" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
const hasKazakhstanTours = (tours) => {
  return tours.some(
    (tour) =>
      typeof tour.region?.country?.country === "string" &&
      tour.region.country.country.includes("Казахстан")
  );
};
