import React, { useEffect, useState } from "react";
import "./styles/modalWindowForOrderTour.css";
import Aos from "aos";
import Notification from "./Notification";
import { usePost } from "../context/SendingPostRequestsProvider";
import NotificationGreen from "./NotificationGreen";

const ModalWindowForOrderTour = ({ onClose, item, selectedCar, tour }) => {
  const { bookTour } = usePost();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+996");
  const [amountOfPeople, setAmountOfPeople] = useState("");
  const [description, setDescription] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationMessageGreen, setNotificationMessageGreen] = useState("");

  const maxLength = 150;
  const maxPhoneLength = 16;

  const openOrderModal = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };

  const handleSave = async () => {
    if (!name.trim() || !phone.trim() || !amountOfPeople.trim()) {
      setNotificationMessage("Заполните данные!");
      openOrderModal();
      return;
    }

    // Validate name and phone length
    if (name.length > 12) {
      setNotificationMessage("Имя не должно превышать 12 символов!");
      openOrderModal();
      return;
    }

    if (phone.replace("+996", "").length > maxPhoneLength) {
      setNotificationMessage("Номер телефона не должен превышать 12 символов!");
      openOrderModal();
      return;
    }

    let formData = new FormData();
    formData.append("first_name", name);
    formData.append("phone_number", phone.toString());
    formData.append("duration", amountOfPeople);
    formData.append("description", description);
    formData.append("car", Number(selectedCar.id));
    formData.append("tour", Number(tour.id));

    try {
      await bookTour(formData);
      setNotificationMessageGreen(
        "Спасибо за заказ! Мы скоро с вами свяжемся."
      );
      openOrderModal();
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setNotificationMessage(
        "Произошла ошибка при заказе тура. Попробуйте снова."
      );
      openOrderModal();
    }
  };

  const handlePhoneChange = (e) => {
    let input = e.target.value;

    if (input.startsWith("+996") && input.length <= maxPhoneLength) {
      setPhone(input);
    } else if (input === "") {
      setPhone("+996");
    } else if (input.length <= maxPhoneLength) {
      setPhone(input);
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 700,
      once: true,
    });
  }, []);

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div onClick={handleOverlayClick} className="modal-win-for-order-overlay">
      <div data-aos="fade-up" className="modal-win-for-order-section">
        <button onClick={onClose} className="modal-close-button">
          X
        </button>
        <h2 className="modal-win-for-order-title-for-order">Ваш заказ</h2>
        <div className="modal-win-for-order-text-info-main">
          <div className="modal-win-for-order-text-info">
            <h3>{item.title}</h3>
            <h4>Выбранная область: {item.region.region}</h4>
          </div>
          <div className="modal-win-for-order-text-info-price">
            <span>{item.price ? `${item.price}сом` : "Договорная"}</span>
          </div>
        </div>
        <h5 className="modal-win-for-order-text-info-more-then-one-times">
          Сумма: <span>{item.price ? `${item.price}сом` : "Договорная"}</span>
        </h5>
        <div className="the-chosen-car-for-order-in-modal-window">
          <h3>Выбранный транспорт:</h3>
          <span>
            {selectedCar.name} ({selectedCar.car_style})
          </span>
        </div>
        <div className="modal-win-for-order-inputs">
          <input
            type="text"
            name="name"
            id="name"
            className="modal-win-for-order-inputs-itself name"
            placeholder="Ваше имя*"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            name="phone"
            id="phone"
            className="modal-win-for-order-inputs-itself phone"
            placeholder="Ваш телефон"
            value={phone}
            onChange={handlePhoneChange}
          />
          <input
            type="number"
            name="amount_of_people"
            id="amount_of_people"
            className="modal-win-for-order-inputs-itself"
            placeholder="Количество человек"
            onChange={(e) => setAmountOfPeople(e.target.value)}
          />
          <textarea
            name="description"
            id="description"
            className="modal-win-for-order-inputs-itself for-addition-info"
            placeholder="Дополнительная информация"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          {description.length > maxLength && (
            <p style={{ color: "red", fontSize: "1.5vh" }}>
              Достигнуто максимальное количество символов (120).
            </p>
          )}
          <button onClick={handleSave} className="modal-win-for-order-btn">
            Заказать
          </button>
        </div>
      </div>
      {notificationVisible && (
        <Notification
          visible={notificationVisible}
          message={notificationMessage}
        />
      )}
      {notificationVisible && (
        <NotificationGreen
          visible={notificationVisible}
          message={notificationMessageGreen}
        />
      )}
    </div>
  );
};

export default ModalWindowForOrderTour;
