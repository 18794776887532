import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "../Auth/Register";
import Login from "../Auth/Login";
import About from "../components/About";
import HomePage from "../pages/HomePage";
import ProductList from "../components/ProductList";
import Reviews from "../components/Reviews";
import FavTour from "../components/FavTour";
import WatchTourMore from "../components/WatchTourMore";
import ReviewDetail from "../components/ReviewDetail";
import NotFound from "../components/NotFoundPage/NotFound";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/about" element={<About />} />
      <Route path="/tours" element={<ProductList />} />
      <Route path="/reviews" element={<Reviews />} />
      <Route path="/favList" element={<FavTour />} />
      <Route path="/tourPage/:id" element={<WatchTourMore />} />
      <Route path="/review_detail" element={<ReviewDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default MainRoutes;
