import React from "react";
import "aos/dist/aos.css";
import Slider from "react-slick";
// Import Swiper styles

const ChooseCarModalWindow = ({ onClose, onSelectCar, carOptions }) => {
  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-window">
        <h2>Выберите машину</h2>
        <div className="custom-car-scroll-container">
          <ul className="custom-car-list">
            {carOptions.map((car) => {
              const sliderSettings = {
                dots: true,
                infinite: car.car_img.length > 1,
                speed: 500,
                autoplay: false,
                autoplaySpeed: 6000,
                slidesToShow: 1,
                slidesToScroll: 1,
              };

              return (
                <li key={car.id} className="custom-car-item">
                  <div className="slider-container">
                    <Slider {...sliderSettings}>
                      {car.car_img.map((pic, index) => (
                        <img
                          className="custom-car-photo"
                          src={pic.img}
                          alt={car.name}
                          key={index}
                        />
                      ))}
                    </Slider>
                  </div>

                  <div className="custom-car-details">
                    <h3>{car.name}</h3>
                    <p>{car.description}</p>
                    <p>
                      <strong>Тип:</strong> {car.car_style}
                    </p>
                    <p>
                      <strong>Год выпуска:</strong> {car.year}
                    </p>
                    <p>
                      <strong>Вместимость:</strong> {car.capacity}
                    </p>
                    <p>
                      <strong>Кондиционер:</strong>{" "}
                      {car.air_codinting === true ? "✅" : "❌"}
                    </p>
                    <p>
                      <strong>Wi-Fi:</strong> {car.wifi === true ? "✅" : "❌"}
                    </p>

                    <button
                      onClick={() => onSelectCar(car)}
                      className="custom-select-button"
                    >
                      Выбрать
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <button onClick={onClose} className="custom-close-button">
          Закрыть
        </button>
      </div>
    </div>
  );
};

export default ChooseCarModalWindow;
