import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./style/mainPage.css";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const MoreInfoMainPage = ({ activeTours }) => {
  const navigate = useNavigate();
  const [autoplay, setAutoplay] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: autoplay,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Функция для обрезки текста
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="carousel">
      <div className="scroll-indicator">
        <h2 className="introduction-to-slides">Наши туры</h2>
        <div className="canvas-for-moving-finger">
          <div className="hand"></div>
        </div>
      </div>
      <Slider
        {...settings}
        onMouseEnter={() => setAutoplay(false)}
        onMouseLeave={() => setAutoplay(true)}
      >
        {activeTours.slice(0, 6).map((item, index) => (
          <div className="box" key={index}>
            <img
              src={item.images.map((e) => e.image)[0]}
              alt=""
              className="photo-preview-of-slider"
              onClick={() => navigate(`/tourPage/${item.id}`)}
            />
            <h3 className="price-about-photo-preview">
              {item.price ? `${parseFloat(item.price).toFixed(0)}с` : null}
            </h3>
            <h3 className="name-about-photo-preview">{item.name}</h3>
            <h3 className="description-text-about-photo-preview">
              {truncateText(item.description, 10)}
            </h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MoreInfoMainPage;
