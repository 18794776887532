import React from "react";
import { AiFillHeart } from "react-icons/ai";

const FavToursCards = ({
  elem,
  navigate,
  deleteTourFromFav,
  truncateText,
  key,
}) => {
  return (
    <div data-aos="fade-down" className="fav-card-section" key={key}>
      <div className="main-info-ab-tour-in-fav">
        <img
          src={elem.item.images.map((e) => e.image)[0]}
          alt=""
          className="fav-list-image-preview"
          onClick={() => navigate(`/tourPage/${elem.item.id}`)}
        />
        <AiFillHeart
          onClick={() => deleteTourFromFav(elem.item.id)}
          className="heart-to-remove-from-fav"
        />
        <div className="other-info-ab-tours-in-fav">
          <span className="fav-card-title">{elem.item.name}</span>
          <span className="fav-card-description">
            {elem.item.region.region}
          </span>
          <span className="fav-card-direction">
            {truncateText(elem.item.description, 4)}
          </span>
        </div>
      </div>
      <div className="all-ab-price-etc-fav">
        <span className="text-ab-price-tour-card fav-cards-price-ab">
          {elem.item.price ? `${elem.item.price}c` : null}
        </span>
        <button
          onClick={() => navigate(`/tourPage/${elem.item.id}`)}
          className="btn-move-to-details-fav"
        >
          Узнать
        </button>
      </div>
    </div>
  );
};

export default FavToursCards;
