import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../helper/const";
export const authContext = createContext();
export const useAuth = () => useContext(authContext);
const AuthContextProvider = ({ children }) => {
  // Navigation
  const navigate = useNavigate();
  // Navigation

  // состояния
  const [currentUser, setCurrentUser] = useState(null);
  const [error, setError] = useState(false);
  const [errorReg, setErrorReg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // состояния

  // регистрация пользователя

  const handleRegister = async (formData) => {
    try {
      await axios.post(`${API}/user/register/`, formData);
      setError(null);
      setIsSuccess(true);
      console.log("успешная регистрация");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.email) {
        const errorMessage = error.response.data.email[0];
        if (errorMessage.includes("user with this email already exists")) {
          setIsSuccess(true);
        } else {
          setErrorReg(errorMessage);
        }
      } else {
        setErrorReg("Error related to password or email.");
      }

      console.log(error.response);
    }
  };

  // регистрация пользователя

  // вход пользователя
  const handleLogin = async (formData, email) => {
    try {
      const { data } = await axios.post(`${API}/user/login/`, formData);
      localStorage.setItem("tokens", JSON.stringify(data));
      localStorage.setItem("email", JSON.stringify(email));
      setCurrentUser(email);
      navigate("/");
      setError(null);
    } catch (error) {
      setError(error.response.data);
    }
  };
  // вход пользователя

  // выход из аккаунта
  const handleLogOut = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("email");
    setCurrentUser(null);
    navigate("/login");
  };
  // выход из аккаунта

  // проверка утентификации пользователя и обновления его токенов
  const checkAuth = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("tokens"));

      if (!tokens || !tokens.refresh) {
        throw new Error("Токены не найдены. Пожалуйста, выполните вход.");
      }

      const response = await axios.post(
        `${API}/user/refresh/`,
        {
          refresh: tokens.refresh,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem(
        "tokens",
        JSON.stringify({
          access: response.data.access,
          refresh: tokens.refresh,
        })
      );

      const email = JSON.parse(localStorage.getItem("email"));
      setCurrentUser(email);
      setError(null);
    } catch (error) {
      console.error(
        "Ошибка при обновлении токена:",
        error.response ? error.response.data : error.message
      );

      if (error.response && error.response.status === 401) {
        console.log("Токены недействительны. Необходимо повторное вход.");
        localStorage.removeItem("tokens");
        localStorage.removeItem("email");
        setCurrentUser(null);
      } else {
        setError("Не удалось обновить токены. Попробуйте еще раз.");
      }
    }
  };
  // проверка утентификации пользователя и обновления его токенов

  // проверка activation code
  const checkCode = async (formData) => {
    try {
      await axios.get(
        `${API}/user/active/${formData.get("email")}/${formData.get(
          "activation_code"
        )}`
      );
      navigate("/login");
      setIsSuccess(false);
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setError("You entered the code incorrectly.");
    }
  };
  // проверка activation code
  const values = {
    handleRegister,
    handleLogin,
    handleLogOut,
    currentUser,
    error,
    checkAuth,
    checkCode,
    isSuccess,
    setIsSuccess,
    errorReg,
  };
  return <authContext.Provider value={values}>{children}</authContext.Provider>;
};

export default AuthContextProvider;
