import React, { useEffect } from "react";
import MainPage from "./MainPage";
import MoreInfoMainPage from "./MoreInfoMainPage";
import CountryCards from "../components/CountryCards";
import ReviewsMainPage from "./ReviewsMainPage";
import { useProduct } from "../context/ProductContextProvider";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { tours, getTours } = useProduct();

  useEffect(() => {
    getTours();
  }, [getTours]);
  const activeTours = tours.filter((tour) => tour.is_active);
  const isKazakhstanTourAvailable = hasKazakhstanTours(activeTours);

  return (
    <div>
      <MainPage isKazakhstan={isKazakhstanTourAvailable} />
      <CountryCards isKazakhstan={isKazakhstanTourAvailable} />
      <MoreInfoMainPage activeTours={activeTours} />
      <ReviewsMainPage />
    </div>
  );
};

export default HomePage;

const hasKazakhstanTours = (tours) => {
  return tours.some(
    (tour) =>
      typeof tour.region?.country?.country === "string" &&
      tour.region.country.country.includes("Казахстан")
  );
};
