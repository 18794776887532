import React from "react";

const NotificationGreen = ({ message, visible }) => {
  return (
    <div className={`notificationGreen ${visible ? "show" : ""}`}>
      {message}
    </div>
  );
};

export default NotificationGreen;
