import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles/watchTourMore.css";
import { AiFillHeart, AiTwotoneHeart } from "react-icons/ai";
import { useFav } from "../context/FavListContextProvider";
import ModalWindowForOrderTour from "./ModalWindowForOrderTour";
import ChooseCarModalWindow from "./ChooseCarModalWindow";
import Notification from "./Notification";
import { PiCursorClickBold } from "react-icons/pi";
import { ImCheckmark } from "react-icons/im";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useProduct } from "../context/ProductContextProvider";
import { Autoplay } from "swiper/modules";
import LoadingPage from "./LoadinPage/LoadingPage";
import { Helmet } from "react-helmet-async";

const WatchTourMore = () => {
  const { location } = useLocation();
  const { id } = useParams();
  const { tours, cars, getCars } = useProduct();
  const { deleteTourFromFav, checkTourInFav, AddTourToFav } = useFav();
  const navigate = useNavigate();
  const [isCarModalOpen, setIsCarModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  const activeTours = tours.filter((tour) => tour.is_active);
  const tour = tours.find((tour) => tour.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const openCarModal = () => setIsCarModalOpen(true);
  const closeCarModal = () => setIsCarModalOpen(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const openOrderModal = () => {
    if (!selectedCar) {
      setNotificationVisible(true);
      setNotificationMessage("Пожалуйста, выберите машину!");
      setTimeout(() => {
        setNotificationVisible(false);
      }, 3000);
      return;
    }
    setIsOrderModalOpen(true);
  };

  const closeOrderModal = () => setIsOrderModalOpen(false);

  const handleSelectCar = (car) => {
    setSelectedCar(car);
    closeCarModal();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeCarModal();
        closeOrderModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [cardsToShow, setCardsToShow] = useState(getCardsToShow());

  function getCardsToShow() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1700) {
      return 6;
    } else if (screenWidth >= 1500) {
      return 5;
    } else if (screenWidth >= 1200) {
      return 4;
    } else if (screenWidth >= 860) {
      return 3;
    } else if (screenWidth >= 550) {
      return 2;
    } else if (screenWidth >= 210) {
      return 4;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    function handleResize() {
      setCardsToShow(getCardsToShow());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getCars();
  }, [getCars]);

  const handleOpenModal = () => {
    if (cars.length === 0) {
      setNotificationMessage("Ошибка при загрузке машин!");
      setNotificationVisible(true);
      setTimeout(() => {
        setNotificationVisible(false);
      }, 3000);
      return;
    } else {
      openCarModal();
    }
  };
  const firstImage = tour ? tour.images.map((i) => i.image) : null;
  const formattedPrice = tour ? parseFloat(tour.price).toFixed(0) : null;

  const [isNavigationEnabled, setIsNavigationEnabled] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsNavigationEnabled(true);
      } else {
        setIsNavigationEnabled(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return tour ? (
    <>
      <Helmet>
        <title>{`${tour.region.country.country}, ${tour.name} - Тур`}</title>
        <meta name="description" content={tour.description.slice(0, 150)} />
        <meta
          name="keywords"
          content={`${tour.region.country.country}, ${tour.name}, тур, ${
            tour.price ? `цена ${formattedPrice}` : "договорная"
          }, путешествия`}
        />
        <link
          rel="canonical"
          href={`https://nomadjourney.website/tourPage/${tour.id}`}
        />
      </Helmet>
      <div className="watch-ab-tour-more-section">
        <div className="title-and-image-part">
          <div className="slider-and-cards-with-price-and-order">
            <div className="slider-with-picture-preview-main">
              <div className="title-of-tour">
                <h2 className="title-for-tour-for-watch-more">
                  {tour.region.country.country}, {tour.name}
                </h2>
              </div>

              <Swiper
                slidesPerView={1}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={isNavigationEnabled}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"
                speed={1000}
                autoplay={{ delay: 5000 }}
                simulateTouch={true}
              >
                {firstImage.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="place-where-we-put-our-pic-ab-tour">
                      <img
                        key={index}
                        src={item}
                        className="photo-review-for-tour-more-inf"
                        alt=""
                      />
                      {checkTourInFav(tour.id) ? (
                        <AiFillHeart
                          onClick={() => deleteTourFromFav(tour.id)}
                          className="add-to-fav-icon-in-more-tour"
                          style={{
                            color: checkTourInFav(tour.id) ? "red" : "",
                          }}
                        />
                      ) : (
                        <AiTwotoneHeart
                          onClick={() => AddTourToFav(tour)}
                          className="add-to-fav-icon-in-more-tour theOppositeOne"
                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="cards-with-info-ab-price-and-order">
              <div className="price-for-tour-itself">
                <h4>Цена в сомах</h4>
                <h5>
                  {tour.price ? `${formattedPrice}сом` : "Договорная"}
                  {tour.price ? <span>/человек</span> : null}
                </h5>
                <div className="summery-info-ab-tour-section">
                  <h3>Основные</h3>
                  <p>{tour.description}</p>
                </div>
              </div>
              <div className="all-btn-related-to-order-watch-tour-more">
                <button
                  onClick={handleOpenModal}
                  className="btn-for-price-for-tour-itself choose-car-btn-special"
                >
                  Выбрать машину
                  {selectedCar ? <ImCheckmark /> : <PiCursorClickBold />}
                </button>

                {isCarModalOpen && (
                  <ChooseCarModalWindow
                    onSelectCar={handleSelectCar}
                    selectedCar={selectedCar}
                    onClose={closeCarModal}
                    carOptions={cars}
                  />
                )}

                <button
                  onClick={openOrderModal}
                  className="btn-for-price-for-tour-itself"
                >
                  Записаться
                </button>

                {isOrderModalOpen && (
                  <ModalWindowForOrderTour
                    item={tour}
                    onClose={closeOrderModal}
                    selectedCar={selectedCar}
                    tour={tour}
                  />
                )}

                <Notification
                  message={notificationMessage}
                  visible={notificationVisible}
                  onClose={onclose}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-where-all-info-ab-tour-yapi">
          <div>
            <div className="is-this-for-me-tour">
              <div className="header-of-section-ab-overview-and-etc">
                <i className="callout-title-icon"></i>
                <h3>Этот тур для меня?</h3>
              </div>
              <div className="info-ab-tour-that-we-need">
                <div className="info-ab-tour-that-we-need-itself themes">
                  <h4>
                    Тема тура:
                    <br />
                    <span>{tour.themes.group_themes}</span>
                  </h4>
                  <p>{tour.themes.description}</p>
                </div>
                <div className="info-ab-tour-that-we-need-itself type">
                  <h4>
                    Тип тура: <br />
                    <span>
                      {
                        (tour.tour_type.with_gid = true
                          ? "С гидом"
                          : "без гида")
                      }
                    </span>
                  </h4>
                  <p>{tour.tour_type.description}</p>
                </div>
                <div className="info-ab-tour-that-we-need-itself group-size">
                  <h4>
                    Группа: <br />
                    <span>{tour.group_detail.group_size}</span>
                  </h4>
                  <p>{tour.group_detail.description}</p>
                </div>
              </div>
            </div>
          </div>

          <div></div>
          <div className="special-notes-tour-more-info-section">
            <div className="header-of-section-ab-overview-and-etc">
              <i className="callout-title-icon"></i>
              <h3>Специальные примечания</h3>
            </div>
            <div className="special-notes-tour-more-info-section-suggestions">
              <ul className="special-notes-tour-more-info-itself">
                <h3>Важное</h3>
                <li>
                  <p>
                    Для защиты вашего личного имущества настоятельно рекомендуем
                    всегда держать все ценные вещи при себе. Не оставляйте их в
                    автобусе, отеле, на экскурсии или в ресторане. Водители и
                    экскурсоводы не несут ответственности за утрату или кражу
                    имущества в любом из этих мест.
                  </p>
                </li>
                <h3>Полезная информация</h3>
                <li>
                  <p>
                    Большая часть путешествия пройдет на значительной высоте.
                    Людям с хроническими заболеваниями следует иметь при себе
                    необходимые лекарства. Рекомендуется взять удобную
                    спортивную обувь, солнцезащитные очки, крем и шляпу.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="recommendations-for-users-tours-section-main">
          <div className="recommendations-for-users-tours-text">
            <h3>Вас также могут заинтересовать</h3>
            <span>Следующие туры</span>
          </div>
          <div className="recommendations-for-users-tours">
            {activeTours
              .filter((elem) => elem.id !== tour.id)
              .slice(0, cardsToShow)
              .map((elem) => (
                <div className="fav-card-section-recom" key={elem.id}>
                  <div className="main-info-ab-tour-in-fav-recom">
                    <img
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        navigate(`/tourPage/${elem.id}`);
                      }}
                      src={elem.images.map((i) => i.image)[0]}
                      alt={elem.name}
                      className="fav-list-image-preview-recom"
                    />

                    <div className="other-info-ab-tours-in-fav-recom">
                      <span className="fav-card-title-recom">{elem.name}</span>
                      <span className="fav-card-description-recom">
                        {truncateText(elem.description, 5)}
                      </span>
                      <span className="fav-card-direction-recom">
                        {elem.region.region}
                      </span>
                    </div>
                  </div>
                  <div className="all-ab-price-etc-fav-recom">
                    <span className="text-ab-price-tour-card-recom fav-cards-price-ab-recom">
                      {elem.price ? `${elem.price}с` : ""}
                    </span>
                    <button
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        navigate(`/tourPage/${elem.id}`);
                      }}
                      className="btn-move-to-details-fav-recom"
                    >
                      Узнать
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingPage />
  );
};

export default WatchTourMore;
