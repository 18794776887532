import React, { useEffect, useState, useCallback } from "react";
import "./styles/reviewsModal.css";
import { useReview } from "../context/ReviewContextProvider";
import Notification from "./Notification";

const ModalWindowForChangingReview = ({ onClose, id }) => {
  const { getOneReview, editReview, oneReview } = useReview();
  const [name, setName] = useState("");
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);

  useEffect(() => {
    getOneReview(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openOrderModal = (message) => {
    setNotificationMessage(message);
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };

  const handleSave = async () => {
    if (rating === 0) {
      openOrderModal("Пожалуйста, выберите рейтинг!");
      return;
    }
    const newReview = new FormData();
    newReview.append("name", name);
    newReview.append("mark", rating);
    newReview.append("text", description);
    await editReview(id, newReview);
    onClose();
    openOrderModal("Отзыв успешно изменен!");
  };

  useEffect(() => {
    setName(oneReview.name);
    setDescription(oneReview.text);
    setRating(oneReview.mark);
  }, [oneReview]);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    setIsDescriptionValid(value.length >= 100);
  };

  const handleClickOutside = useCallback(
    (e) => {
      if (e.currentTarget === e.target) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="reviews_modal">
      <div className="reviews_modal_content">
        <div className="modal_span">
          <span>Оставьте свой отзыв!</span>
        </div>
        <div>
          <div>
            <textarea
              className="review_modal_inp reviewName"
              placeholder="Ваше имя"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="reviews_rating">
            {[1, 2, 3, 4, 5].map((elem) => (
              <div
                key={elem}
                className="review_stars starsModal"
                style={{
                  color: rating >= elem ? "orange" : "black",
                }}
                onClick={() => setRating(elem)}
                tabIndex={elem}
                aria-label={`Rate ${elem} star${elem > 1 ? "s" : ""}`}
              >
                ★
              </div>
            ))}
          </div>
          <div className="review_modal_text_div">
            <textarea
              style={{ border: !isDescriptionValid ? "1px solid red" : "" }}
              className="review_modal_inp reviewText"
              placeholder="Ваш отзыв"
              onChange={handleDescriptionChange}
              value={description}
            />
          </div>
        </div>
        <div className="review_modal_btn_div">
          <button className="review_modal_btn btnAdd" onClick={handleSave}>
            Оставить отзыв
          </button>
          <button className="review_modal_btn btnCancel" onClick={onClose}>
            Отмена
          </button>
          {notificationVisible && (
            <Notification
              visible={notificationVisible}
              message={notificationMessage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalWindowForChangingReview;
