import axios from "axios";
import { API } from "../helper/const";
import React, { createContext, useContext, useState } from "react";
const SendRequestCreate = createContext();
export const usePost = () => useContext(SendRequestCreate);

const SendingPostRequestsProvider = ({ children }) => {
  // states
  const [errorPost, setErrorPost] = useState("");
  // states

  // booking tour
  const bookTour = async (formData) => {
    try {
      await axios.post(`${API}/tour/booking_create/`, formData);
      setErrorPost(null);
    } catch (error) {
      setErrorPost(error);
    }
  };
  // booking tour

  const values = {
    bookTour,
    errorPost,
  };
  return (
    <SendRequestCreate.Provider value={values}>
      {children}
    </SendRequestCreate.Provider>
  );
};

export default SendingPostRequestsProvider;
