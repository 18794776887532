import React, { useRef } from "react";
import "./styles/countryCards.css";
import { useNavigate } from "react-router-dom";

const CountryCards = ({ isKazakhstan }) => {
  const cardRefs = useRef([]);
  const navigate = useNavigate();

  const handleMouseOver = (index) => {
    cardRefs.current[index].style.transform = "scale(1.01)";
  };

  const handleMouseOut = (index) => {
    cardRefs.current[index].style.transform = "scale(1)";
  };

  const goToTours = (country) => {
    navigate(`/tours?country=${country}`);
  };

  return (
    <div className="country-cards-wrapper">
      <div className="country-cards-section">
        <div
          className="country-card-itself kg"
          style={{
            textAlign: isKazakhstan ? "inherit" : "center",
            alignItems: isKazakhstan ? "inherit" : "center",
          }}
          ref={(el) => (cardRefs.current[0] = el)}
        >
          <h3>Путешествие по Кыргызстану</h3>
          {isKazakhstan ? (
            <span>
              Исследуйте Тянь-Шань и окунитесь в кочевую культуру. Наслаждайтесь
              горными пейзажами и бирюзовыми озерами.
            </span>
          ) : (
            <span>
              Исследуйте величественные горы Тянь-Шань и окунитесь в кочевую
              культуру. Наслаждайтесь горными пейзажами, бирюзовыми озерами,
              такими как Иссык-Куль и Сон-Куль, и остановитесь в юртах. Откройте
              для себя уникальные традиции, попробуйте национальные блюда и
              ощутите гармонию с природой среди диких ландшафтов.
            </span>
          )}
          <button
            onClick={() => goToTours("Кыргызстан")}
            onMouseOver={() => handleMouseOver(0)}
            onMouseOut={() => handleMouseOut(0)}
          >
            Узнать больше
          </button>
        </div>
        {isKazakhstan && (
          <div
            className="country-card-itself kz"
            ref={(el) => (cardRefs.current[1] = el)}
          >
            <h3>Приключение в Казахстане</h3>
            <span>
              От степей до горных вершин — незабываемые маршруты и древние
              города. Откройте для себя историю и современность Казахстана.
            </span>
            <button
              onClick={() => goToTours("Казахстан")}
              onMouseOver={() => handleMouseOver(1)}
              onMouseOut={() => handleMouseOut(1)}
            >
              Узнать больше
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountryCards;
