import "../Auth/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContextProvider";
import Notification from "../components/Notification";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const { handleLogin } = useAuth();
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleSave = async (event) => {
    event.preventDefault();

    if (!email.trim() || !password.trim()) {
      setNotificationMessage("Заполните данные!");
      openOrderModal();
      return;
    }

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const success = await handleLogin(formData, email);

    if (!success) {
      setLoginError("Не удалось войти. Пожалуйста, попробуйте снова.");
    } else {
      setLoginError("");
    }
  };

  const navigate = useNavigate();

  const openOrderModal = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };
  return (
    <div className="log-auth-main">
      <div className="log-auth-switch__container" id="switch-c1">
        <h2 className="log-auth-switch__title log-auth-title">Привет, друг!</h2>
        <p className="log-auth-switch__description log-auth-description">
          Введите ваши личные данные и начните путешествие с нами
        </p>
        <button
          onClick={() => navigate("/register")}
          className="log-auth-switch__button log-auth-button log-auth-switch-btn"
        >
          СОЗДАТЬ
        </button>
      </div>
      <div className="log-auth-container log-auth-a-container" id="a-container">
        <form className="log-auth-form" id="a-form">
          <h2 className="log-auth-form_title log-auth-title">
            Войдите на сайт
          </h2>
          <span className="log-auth-form__span">или используйте ваш email</span>
          <input
            className="log-auth-form__input"
            type="text"
            placeholder="Электронная почта"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="log-auth-form__input"
            type="password"
            placeholder="Пароль"
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="log-btn-div-for-moving-to-opposite">
            <span className="log-phone-ask-ab-account">
              Если у вас нет аккаунта
              <Link to={"/register"}>создайте его</Link>
            </span>
            {loginError && <p className="error-text">{loginError}</p>}
            <button
              className="log-auth-form__button log-auth-button submit"
              onClick={handleSave}
            >
              ВОЙТИ
            </button>
            {notificationVisible && (
              <Notification
                visible={notificationVisible}
                message={notificationMessage}
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
