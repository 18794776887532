import { Link } from "react-router-dom";
import style from "./notFound.module.css";
import React from "react";

const NotFound = () => {
  return (
    <div className={style.container}>
      <h1 className={style.mainText}>Ошибка</h1>
      <h2 className={style.h2}>Данная страница не найдена</h2>
      <p className={style.p}>Перейдите на главную страницу</p>
      <Link style={{ textDecoration: "none" }} to={"/"}>
        <button className={style.button}>Главная</button>
      </Link>
    </div>
  );
};

export default NotFound;
