import React from "react";
import "./style/mainPage.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MainPage = ({ isKazakhstan }) => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          {isKazakhstan
            ? "Туры в Кыргызстан и Казахстан"
            : "Туры по Кыргызстану"}
        </title>
        <meta
          name="description"
          content={
            isKazakhstan
              ? "Захватывающие туры по Кыргызстану и Казахстану. Откройте уникальные маршруты и места с эксклюзивными турами."
              : "Уникальные туры по красивым местам Кыргызстана. Исследуйте красивейшие уголки и наслаждайтесь незабываемыми впечатлениями."
          }
        />
        <meta
          name="keywords"
          content="туры, Кыргызстан, Казахстан, путешествия, экскурсии, красивые места"
        />
        <link rel="canonical" href="https://yourwebsite.com/tours" />
      </Helmet>

      <div className="main-page-container">
        <div className="text-container">
          <h1 className="title">
            {isKazakhstan
              ? "Различные туры в Кыргызстан и Казахстан"
              : "Уникальные туры по красивым местам Кыргызстана"}
          </h1>
          <p className="description">
            {isKazakhstan
              ? "Погрузитесь в захватывающие путешествия по Кыргызстану и Казахстану. Откройте новые горизонты и впечатления с нашими эксклюзивными турами."
              : "Погрузитесь в захватывающие путешествия по Кыргызстану. Откройте новые горизонты и удивительные впечатления с нашими уникальными турами."}
          </p>

          <button
            onClick={() => navigate("/tours")}
            className="consultation-button"
            aria-label="Изучить туры подробно"
          >
            Изучить подробно
          </button>
        </div>
        <div
          className="image-container"
          role="img"
          aria-label="Изображение красивых мест Кыргызстана или Казахстана"
        ></div>
      </div>
    </>
  );
};

export default MainPage;
