import React from "react";
import Navbar from "./components/Navbar";
import MainRoutes from "./routes/MainRoutes";
import Footer from "./pages/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";

const App = () => {
  return (
    <div>
      <ScrollToTopButton />
      <Navbar />
      <MainRoutes />
      <Footer />
    </div>
  );
};

export default App;
