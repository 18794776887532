import React, { useEffect, useState } from "react";
import ReviewsModal from "./ReviewsModal";
import ModalWindowForChangingReview from "./ModalWindowForChangingReview";
import "./styles/reviews.css";
import ReviewDetail from "./ReviewDetail";
import { useReview } from "../context/ReviewContextProvider";
import { useAuth } from "../context/AuthContextProvider";
import Notification from "./Notification";
import { Helmet } from "react-helmet-async";

const Reviews = () => {
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const openOrderModal = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRevModalOpen, setIsRevModalOpen] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const { currentUser } = useAuth();
  const { reviews, getReviews, deleteReview } = useReview();

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const truncateText = (text, limit) => {
    if (text.length <= limit) {
      return { text, truncated: false };
    }
    return {
      text: text.slice(0, limit) + "...",
      truncated: true,
    };
  };

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  const handleModalClick = () => {
    if (currentUser) {
      setIsModalOpen(true);
    } else {
      setNotificationMessage("Сперва нужно войти в аккаунт");
      setNotificationVisible(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (notificationVisible) {
      const timer = setTimeout(() => {
        setNotificationVisible(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notificationVisible]);

  const sortedReviews = [...reviews].sort((a, b) => {
    if (a.email === currentUser && b.email !== currentUser) {
      return -1;
    } else if (a.email !== currentUser && b.email === currentUser) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="reviews_wrapper">
      <Helmet>
        <title>
          Отзывы наших клиентов | Ваш надежный партнер для путешествий
        </title>
        <meta
          name="description"
          content="Прочитайте отзывы наших довольных клиентов и узнайте, как мы помогаем организовывать незабываемые путешествия."
        />
        <meta
          name="keywords"
          content="отзывы, путешествия, туры, клиенты, отзывы туристов, отзывы о туризме"
        />
      </Helmet>
      <div className="show-background-picture revPage">
        <span className="show-background-title">Отзывы наших клиентов</span>
      </div>
      <div className="reviews_container">
        <div className="reviews_upper">
          <div className="reviews_title">
            Наше доказательство того что нам можно доверять!
          </div>
          <div className="reviews_modal_div">
            <button className="reviews_modal_btn" onClick={handleModalClick}>
              Оставить отзыв
            </button>
            {isModalOpen && currentUser && (
              <ReviewsModal
                openOrderModal={openOrderModal}
                onClose={() => setIsModalOpen(false)}
              />
            )}
          </div>
        </div>
        <div className="reviews_cards">
          {sortedReviews.map((review) => {
            const { text, truncated } = truncateText(review.text, 240);

            return (
              <div className="review_card" key={review.id}>
                <div className="review_card_name">
                  {review.name}
                  {currentUser === review.email ? (
                    <div className="btn-for-owner-of-review">
                      <button onClick={() => deleteReview(review.id)}>
                        Удалить
                      </button>
                      <button
                        onClick={() => {
                          setSelectedReview(review);
                          setIsChangeModalOpen(true);
                        }}
                      >
                        Изменить
                      </button>
                    </div>
                  ) : null}
                </div>

                <div className="review_card_rating">
                  {[1, 2, 3, 4, 5].map((elem) => (
                    <div
                      className="review_stars"
                      style={{
                        color: review.mark >= elem ? "orange" : "black",
                      }}
                      key={elem}
                    >
                      ★
                    </div>
                  ))}
                </div>
                <div className="reviews_text">
                  <span>{text}</span>
                </div>
                {truncated && (
                  <div className="review_card_show_more_div">
                    <button
                      onClick={() => {
                        handleReviewClick(review);
                        setIsRevModalOpen(true);
                      }}
                      className="review_card_show_more_btn"
                    >
                      читать полностью
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {isRevModalOpen && (
          <ReviewDetail
            review={selectedReview}
            handleClose={() => setIsRevModalOpen(false)}
          />
        )}
        {isChangeModalOpen && (
          <ModalWindowForChangingReview
            onClose={() => setIsChangeModalOpen(false)}
            id={selectedReview?.id}
          />
        )}
        {notificationVisible && (
          <Notification
            visible={notificationVisible}
            message={notificationMessage}
          />
        )}
      </div>
    </div>
  );
};

export default Reviews;
