import style from "./LoadingPage.module.css";
import React from "react";

const LoadingPage = () => {
  return (
    <div className={style.loaderForWebSite}>
      <div className={style.loadingShit}></div>
    </div>
  );
};

export default LoadingPage;
