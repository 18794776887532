// используется для получения данных с хранилища
export const getLocalStorage = () => {
	const fav = JSON.parse(localStorage.getItem('fav'))
	return fav
}

// функция для подсчета суммы всех товаров в корзине
export const getToursCountInFav = () => {
	let fav = getLocalStorage()
	return fav ? fav.tours.length : 0
}
