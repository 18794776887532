import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import "./styles/styleForTourSectionCards.css";
import { useSearchParams } from "react-router-dom";
import { useProduct } from "../context/ProductContextProvider";
import notFoundTour from "../Assets/img/notFoundTour.png";
import { Helmet } from "react-helmet-async";

const ProductList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const { getTours, tours } = useProduct();

  useEffect(() => {
    getTours();
  }, [getTours]);

  const activeTours = tours.filter((tour) => tour.is_active);

  const [selectedCountry, setSelectedCountry] = useState(
    searchParams.get("country") || null
  );
  const [selectedRegion, setSelectedRegion] = useState(
    searchParams.get("region") || null
  );
  const [priceFrom, setPriceFrom] = useState(
    searchParams.get("priceFrom") || ""
  );
  const [priceUntil, setPriceUntil] = useState(
    searchParams.get("priceUntil") || ""
  );
  const search = searchParams.get("q") || "";

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedCountry) params.set("country", selectedCountry);
    if (selectedRegion) params.set("region", selectedRegion);
    if (priceFrom) params.set("priceFrom", priceFrom);
    if (priceUntil) params.set("priceUntil", priceUntil);
    if (search) params.set("q", search);
    setSearchParams(params);
  }, [
    selectedCountry,
    selectedRegion,
    priceFrom,
    priceUntil,
    search,
    getTours,
    setSearchParams,
  ]);

  const filteredTours = activeTours.filter((tour) => {
    const matchesCountry =
      !selectedCountry || tour.region.country.country === selectedCountry;
    const matchesRegion =
      !selectedRegion || tour.region.region === selectedRegion;
    const matchesSearch = tour.name
      .toLowerCase()
      .includes(search.toLowerCase());
    const matchesPrice =
      (!priceFrom || tour.price >= parseFloat(priceFrom)) &&
      (!priceUntil || tour.price <= parseFloat(priceUntil));

    return matchesCountry && matchesRegion && matchesSearch && matchesPrice;
  });

  const handleRegionClick = (region) => {
    setSelectedRegion(region);
  };

  const uniqueCountries = [
    ...new Set(activeTours.map((tour) => tour.region.country.country)),
  ];

  const hasTourPrice = (tours) => {
    return activeTours.some(
      (tour) => typeof tour.price === "string" && tour.price !== null
    );
  };

  const isPriceTourAvailable = hasTourPrice(tours);

  const [activeIndexes, setActiveIndexes] = useState([]);

  const toggleMenu = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes([]);
    } else {
      setActiveIndexes([index]);
    }
    setSelectedRegion(null);
  };

  return (
    <div className="tour-section-container">
      <Helmet>
        <title>
          Туры по Казахстану и Кыргызстану - Найдите свой идеальный тур
        </title>
        <meta
          name="description"
          content="Ищите туры по Казахстану и Кыргызстану. Ознакомьтесь с нашими популярными направлениями, ценами и предложениями на любой вкус!"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Туры по Казахстану и Кыргызстану" />
        <meta
          property="og:description"
          content="Ищите туры по Казахстану и Кыргызстану. Ознакомьтесь с нашими популярными направлениями, ценами и предложениями на любой вкус!"
        />
        <meta property="og:image" content={notFoundTour} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="show-background-picture prCardsBg">
        Найди тур для себя
      </div>
      <div className="tour-section-divider-to-two">
        <div className="filter-for-tours-lol">
          <h3>Фильтр</h3>
          <div className="line-for-filter"></div>
          <div className="category-filter-lol">
            <h4 className="category-filter-lol-title">По месту</h4>
            <button
              className={`country-filter all-button ${
                selectedCountry === null ? "selected" : ""
              }`}
              onClick={() => {
                setSelectedCountry(null);
                setSelectedRegion(null);
                setActiveIndexes([]);
              }}
            >
              Все
            </button>
            <div className="country-region-container-main">
              {uniqueCountries.map((country, index) => {
                const regionsForCountry = activeTours
                  .filter((tour) => tour.region.country.country === country)
                  .map((tour) => tour.region.region);
                const uniqueRegions = [...new Set(regionsForCountry)];

                return (
                  <div className="country-region-container" key={index}>
                    <button
                      className={`button ${
                        selectedCountry === country ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedCountry(country);
                        toggleMenu(index);
                      }}
                    >
                      {country}
                      <span
                        className={`arrow ${
                          activeIndexes.includes(index) ? "up" : "down"
                        }`}
                      ></span>
                    </button>
                    <ul
                      className="menu"
                      style={{
                        maxHeight: activeIndexes.includes(index)
                          ? `${uniqueRegions.length * 40}px`
                          : "0",
                        transition: "max-height 0.5s ease-in-out",
                      }}
                      aria-hidden={!activeIndexes.includes(index)}
                    >
                      {uniqueRegions.map((region, regionIndex) => (
                        <li
                          key={regionIndex}
                          onClick={() => handleRegionClick(region)}
                          className={`menu-item ${
                            selectedRegion === region ? "selected" : ""
                          }`}
                        >
                          <span className="text-regions">{region}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
          {isPriceTourAvailable && (
            <div className="price--filter-asap">
              <span className="category-filter-lol-title price-title-for-filter">
                Цена:
              </span>
              <div className="inp-price-for-filter">
                <input
                  type="number"
                  placeholder="Цена от"
                  value={priceFrom}
                  onChange={(e) => setPriceFrom(e.target.value)}
                />
                <input
                  type="number"
                  placeholder="Цена до"
                  value={priceUntil}
                  onChange={(e) => setPriceUntil(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {filteredTours.length > 0 ? (
            <>
              <div className="tours-cards-wrapper">
                {filteredTours.map((item) => (
                  <ProductCard item={item} key={item.id} />
                ))}
              </div>
            </>
          ) : (
            <div data-aos="fade-down" className="tours-section-main">
              <div className="tours-cards-main">
                <div className="div-for-cards-preview">
                  <img
                    src={notFoundTour}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="main-text-about-tours">
                  <span className="name-ab-tours">По данному запросу</span>
                  <span className="info-where-tour-starts">
                    Ничего не найдено
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
