import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import FavListContextProvider from "./context/FavListContextProvider";
import AuthContextProvider from "./context/AuthContextProvider";
import SendingPostRequestsProvider from "./context/SendingPostRequestsProvider";
import ProductContextProvider from "./context/ProductContextProvider";
import ReviewContextProvider from "./context/ReviewContextProvider";
import LoadingPage from "./components/LoadinPage/LoadingPage";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <FavListContextProvider>
      <ProductContextProvider>
        <SendingPostRequestsProvider>
          <ReviewContextProvider>
            <AuthContextProvider>
              <Suspense fallback={<LoadingPage />}>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </Suspense>
            </AuthContextProvider>
          </ReviewContextProvider>
        </SendingPostRequestsProvider>
      </ProductContextProvider>
    </FavListContextProvider>
  </BrowserRouter>
);
