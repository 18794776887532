import axios from "axios";
import { API } from "../helper/const";
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
const reviewContext = createContext();
export const useReview = () => useContext(reviewContext);

const INIT_STATE = {
  reviews: [],
  oneReview: [],
};

const ReviewContextProvider = ({ children }) => {
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case "GET_REVIEWS":
        return { ...state, reviews: action.payload };
      case "GET_ONE_REVIEW":
        return { ...state, oneReview: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  // config
  const getConfig = () => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (!tokens || !tokens.access) {
      console.error("No tokens found");
      return { error: "No tokens found" };
    }
    const Authorization = `Bearer ${tokens.access}`;
    return {
      headers: { Authorization },
    };
  };
  // config

  // post reviews
  const addReview = async (newReview) => {
    try {
      await axios.post(`${API}/tour/review_create/`, newReview, getConfig());
      return "Успешная отправка!";
    } catch (error) {
      let errorMessage = "Ошибка попробуйте позже!";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = "Неверный запрос. Проверьте введенные данные.";
            break;
          case 404:
            errorMessage = "Не удалось найти ресурс.";
            break;
          case 500:
            errorMessage = "Ошибка на сервере. Попробуйте позже.";
            break;
          default:
            errorMessage = "Неизвестная ошибка. Попробуйте позже.";
        }
      } else if (error.request) {
        errorMessage = "Нет ответа от сервера. Проверьте соединение.";
      } else {
        errorMessage = "Ошибка настройки запроса.";
      }

      console.error("Ошибка при добавлении отзыва:", error);
      return errorMessage;
    }
  };
  // post reviews

  // get reviews
  const getReviews = useCallback(async () => {
    try {
      const { data } = await axios(`${API}/tour/get_review`);
      dispatch({
        type: "GET_REVIEWS",
        payload: data.results,
      });
    } catch (error) {
      console.log("Ошибка при получении отзывов:", error);
    }
  }, []);
  // get reviews

  // delete review
  const deleteReview = async (id) => {
    try {
      await axios.delete(`${API}/tour/review_delete/${id}`, getConfig());
      getReviews();
    } catch (error) {
      console.log(error);
    }
  };
  // delete review

  // getOneReview
  const getOneReview = async (id) => {
    try {
      const { data } = await axios(
        `${API}/tour/retrieve_review/${id}/`,
        getConfig()
      );
      dispatch({
        type: "GET_ONE_REVIEW",
        payload: data,
      });
    } catch (error) {
      console.log("Error fetching one review:", error);
    }
  };
  // getOneReview

  // edit
  const editReview = async (id, newReview) => {
    try {
      await axios.patch(
        `${API}/tour/update_delete/${id}/`,
        newReview,
        getConfig()
      );
      getReviews();
    } catch (error) {
      console.log("Error editing review:", error);
    }
  };
  // edit

  const values = {
    addReview,
    reviews: state.reviews,
    oneReview: state.oneReview,
    getReviews,
    deleteReview,
    getConfig,
    editReview,
    getOneReview,
  };

  return (
    <reviewContext.Provider value={values}>{children}</reviewContext.Provider>
  );
};

export default ReviewContextProvider;
