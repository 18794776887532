import React, { useState, useEffect, useRef } from "react";
import "./styles/navbar.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import mainLogo from "../Assets/img/navlogoMain.svg";
import { getToursCountInFav } from "../helper/function";
import { useFav } from "../context/FavListContextProvider";
import { IoPersonCircleSharp } from "react-icons/io5";
import { useAuth } from "../context/AuthContextProvider";

const Navbar = () => {
  const { handleLogOut, currentUser, checkAuth } = useAuth();

  useEffect(() => {
    if (localStorage.getItem("tokens")) {
      checkAuth();
    }
  }, [checkAuth]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRefShit = useRef(null);
  const burgerRef = useRef(null);
  const menuRef = useRef(null);
  const location = useLocation();

  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      burgerRef.current &&
      !burgerRef.current.contains(event.target) &&
      dropdownRefShit.current &&
      !dropdownRefShit.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
      setIsOpenShit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const { fav } = useFav();
  const [badgeCount, setBadgeCount] = React.useState();
  React.useEffect(() => {
    setBadgeCount(getToursCountInFav());
  }, [fav]);
  const [isOpenShit, setIsOpenShit] = useState(false);

  const toggleDropdownShit = () => {
    setIsOpenShit(!isOpenShit);
  };
  useEffect(() => {
    setIsOpenShit(false);
  }, [location]);

  const getTrimmedEmail = (email) => {
    const atIndex = email.indexOf("@");
    return atIndex !== -1 ? email.slice(0, atIndex) : email;
  };

  const handleClickAuth = () => {
    if (currentUser) {
      toggleDropdownShit();
    } else {
      navigate("/login");
    }
  };

  const navigate = useNavigate();

  const isActiveLink = (pathName) =>
    location.pathname === pathName ? "active" : "";

  return (
    <div className={`navbar ${isMenuOpen ? "open" : ""}`}>
      <div className="container">
        <button
          className="header__burger-btn"
          ref={burgerRef}
          onClick={handleBurgerClick}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <img
          onClick={() => navigate("/")}
          src={mainLogo}
          alt="Navbar Logo"
          className="nav-logo"
        />
        <div className="nav-main-text" ref={menuRef}>
          <div className="nav-drop-down-main tours-dropdown">
            <Link
              to="/tours"
              className={`main-text-links ${isActiveLink("/tours")}`}
            >
              Туры
            </Link>
          </div>
          <Link
            to="/reviews"
            className={`main-text-links ${isActiveLink("/reviews")}`}
          >
            <span>Отзывы</span>
          </Link>
          <Link
            to="/about"
            className={`main-text-links ${isActiveLink("/about")}`}
          >
            <span>О нас</span>
          </Link>
          <Link
            to="/favList"
            className={`main-text-links ${isActiveLink("/favList")}`}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Избранные
              {badgeCount ? (
                <span
                  style={{
                    backgroundColor: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "17px",
                    borderRadius: "0 50% 0 50%",
                    height: "17px",
                    marginLeft: "5px",
                    fontSize: "15px",
                  }}
                >
                  {badgeCount}
                </span>
              ) : null}
            </span>
          </Link>
          <div
            ref={dropdownRefShit}
            onClick={handleClickAuth}
            className={`auth-part-of-wite-main for-bg-menu ${
              isOpenShit ? "dropdown-open" : ""
            }`}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <IoPersonCircleSharp className="auth-part-of-wite-main-icon" />
            <span>
              {currentUser ? getTrimmedEmail(currentUser) : "Войдите"}
            </span>
            {isOpenShit && (
              <div
                className="drop-down-content-auth"
                onClick={() => navigate("/")}
              >
                <span
                  onClick={handleLogOut}
                  className="drop-down-content-auth-exit"
                >
                  Выйти
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="btn-lan-switcher-and-auth-part">
          <div
            ref={dropdownRefShit}
            onClick={handleClickAuth}
            className={`auth-part-of-wite-main stay ${
              isOpenShit ? "dropdown-open" : ""
            }`}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <IoPersonCircleSharp className="auth-part-of-wite-main-icon" />
            <span>
              {currentUser ? getTrimmedEmail(currentUser) : "Войдите"}
            </span>
            {isOpenShit && (
              <div
                className="drop-down-content-auth"
                onClick={() => navigate("/")}
              >
                <span
                  onClick={handleLogOut}
                  className="drop-down-content-auth-exit"
                >
                  Выйти
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
