import Aos from "aos";
import React, { useEffect } from "react";
import { useFav } from "../context/FavListContextProvider";
import { AiFillHeart, AiTwotoneHeart } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";

const ProductCard = ({ item }) => {
  // animation
  useEffect(() => {
    Aos.init({
      duration: 500,
      once: true,
    });
  }, []);
  // animation

  // все связанное с избранное
  const { AddTourToFav, checkTourInFav } = useFav();
  // все связанное с избранное

  // обрезка слов
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  // обрезка слов

  // router-dom
  const navigate = useNavigate();
  // router-dom

  // форматирование
  const firstImage = item.images.map((i) => i.image)[0];
  const formattedPrice = parseFloat(item.price).toFixed(0);
  // форматирование

  return (
    <div data-aos="fade-down" className="tours-section-main">
      <div className="tours-cards-main">
        <div className="div-for-cards-preview">
          <Link to={`/tourPage/${item.id}`}>
            <img src={firstImage} alt="" />
          </Link>
          {checkTourInFav(item.id) ? (
            <AiFillHeart
              onClick={() => AddTourToFav(item)}
              className="add-to-fav-icon"
              style={{
                color: checkTourInFav(item.id) ? "red" : "",
              }}
            />
          ) : (
            <AiTwotoneHeart
              className="add-to-fav-icon notInfav"
              onClick={() => AddTourToFav(item)}
            />
          )}
        </div>
        <div className="main-text-about-tours">
          <span className="name-ab-tours">{item.name}</span>
          <span className="info-where-tour-starts">
            {item.region.country.country}
          </span>
          <span className="info-where-tour-starts">
            {/* {item.start_place} / {item.end_place} */}
            {item.region.region}
          </span>
          <div className="short-info-main">
            <span className="short-info-ab-tour">
              {truncateText(item.description, 20)}
            </span>
          </div>
        </div>
        <div className="all-ab-price-etc">
          <button
            onClick={() => navigate(`/tourPage/${item.id}`)}
            className="btn-move-to-details"
          >
            Узнать
          </button>
          <span className="text-ab-price-tour-card">
            {item.price ? `${formattedPrice}с` : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
