import React, { useEffect } from "react";
import "./styles/about.css";
import bgVideoKyrgyz from "../Assets/vid/videoFootageForBgAboutPage.mp4";
import bossPhoto from "../Assets/img/photoOfBoss.jpg";
import { Helmet } from "react-helmet-async";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about_container">
      <Helmet>
        <title>О нас | Nomad Journey</title>
        <meta
          name="description"
          content="Узнайте о компании Nomad Journey и нашей миссии по улучшению трансферных услуг в Кыргызстане для путешественников, посещающих Иссык-Куль."
        />
        <meta
          name="keywords"
          content="Nomad Journey, трансфер, Иссык-Куль, Кыргызстан, туризм, путешествия, трансферные услуги"
        />
        <meta property="og:title" content="О компании Nomad Journey" />
        <meta
          property="og:description"
          content="Узнайте о нашем сайте, который создан для удобного взаимодействия между туристами и водителями."
        />
        <meta property="og:image" content={bossPhoto} />
      </Helmet>

      <div className="about_story_of_company">
        <div className="about_story_of_company_text">
          <div className="about_story_of_company_upper_text">
            <span>Наша идея</span>
            <br />
            <span className="about_story_of_company_upper_text_idea_span">
              Воплощённая в реальность
            </span>
          </div>
          <div className="about_story_of_company_lower_text">
            <span className="about_story_of_company_lower_text_dear_friends_span">
              Дорогие путешевственники
            </span>
            <br />
            <br />
            <span className="about_story_of_company_lower_text_idea_span1">
              Мы создаем этот сайт для того, чтобы облегчить связь между
              туристами, желающими посетить красивейший Иссык-Куль в
              Кыргызстане, и водителями, предоставляющими трансферные услуги.
              Наша цель – предоставить нашим клиентам возможность напрямую
              связываться с таксистами, минуя посредников, что позволяет
              предлагать оптимальные цены и гарантировать комфортную поездку на
              современных автомобилях.
            </span>
            <br />
            <br />
            <span>
              Мы тщательно подбираем водителей, чтобы обеспечить высокий уровень
              сервиса. Наши водители — профессионалы с многолетним опытом,
              которые отличаются пунктуальностью, честностью и ответственностью.
              Мы уверены, что благодаря нашему сайту вы сможете воспользоваться
              широким спектром услуг и насладиться удобством и безопасностью
              поездок.
            </span>
            <br />
            <br />
            <span>С уважением,</span>
            <br />
            <br />
            <span>
              Если у вас есть вопросы или предложения, будем рады связаться с
              вами!
            </span>
            <br />
            <br />
            <span className="about_director">
              Основатель / Директор — Абдурашидов Тахир Ташбулатович
            </span>
          </div>
        </div>
        <div
          style={{ backgroundImage: `url(${bossPhoto})` }}
          className="about_story_of_company_img"
        ></div>
      </div>
      <div className="about_company_info">
        <video autoPlay muted loop id="background-video">
          <source src={bgVideoKyrgyz} type="video/mp4" />
          Ваш браузер не поддерживает данное видео
        </video>
        <div className="about_company_info_left">
          <div className="about_company_info_left_div">
            <span className="about_company_info_left_span">О компании</span>
            <span className="about_company_info_name">Nomad Journey</span>
            <img src="" alt="" />
          </div>
        </div>
        <div className="about_company_info_right">
          <p>
            Этот сайт создан с целью привлечения большего числа туристов в
            Казахстан и Кыргызстан. Мы стремимся показать уникальную красоту
            этих стран, их богатую культуру и историческое наследие, привлекая
            всё больше путешественников как из-за рубежа, так и изнутри страны.
            Наша платформа предоставляет удобный доступ к информации о самых
            живописных местах, лучших маршрутах и достопримечательностях. Мы
            хотим, чтобы каждый путешественник смог открыть для себя все
            прелести этих регионов, наслаждаясь незабываемыми впечатлениями и
            яркими эмоциями. Мы надеемся, что наш сайт станет надежным
            помощником в планировании вашего идеального путешествия.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
