import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./style/reviewsMainPage.css";
import { useReview } from "../context/ReviewContextProvider";

const ReviewsMainPage = () => {
  const { reviews, getReviews } = useReview();
  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <div className="reviews-main-container">
      <div className="reviews-header">
        <span>Несколько наших отзывов</span>
      </div>
      <div className="reviews-list">
        {reviews
          .filter((review) => review.mark === 5)
          .slice(0, 3)
          .map((review) => (
            <div className="review-card" key={review.id}>
              <span className="review-author">{review.name}</span>
              <div className="review_card_rating">
                {[1, 2, 3, 4, 5].map((elem) => (
                  <div
                    className="review_stars"
                    style={{
                      color: review.mark >= elem ? "orange" : "black",
                    }}
                    key={elem}
                  >
                    ★
                  </div>
                ))}
              </div>
              <span className="review-text">{review.text}</span>
            </div>
          ))}
      </div>
      <div className="reviews-footer">
        <Link to={"/reviews"}>
          <button className="reviews-button">
            <span>Все отзывы</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ReviewsMainPage;
