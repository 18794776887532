import React, { useEffect } from "react";
import "./styles/reviewDetail.css";
import { IoClose } from "react-icons/io5";

const ReviewDetail = ({ review, handleClose }) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.className === "review_detail_container") {
        handleClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="review_detail_container">
      <div className="review_detail_content">
        <div className="review_detail_header">
          <div className="review_detail_title">Отзыв</div>
          <div className="review_detail_close">
            <IoClose
              id="ioClose"
              onClick={handleClose}
              style={{ color: "red" }}
            />
          </div>
        </div>
        <div className="review_detail_name">{review.name}</div>
        <div className="review_detail_rating">
          {[1, 2, 3, 4, 5].map((elem) => (
            <div
              className="review_stars"
              style={{
                color: review.mark >= elem ? "orange" : "black",
              }}
              key={elem}
            >
              ★
            </div>
          ))}
        </div>
        <div className="reviews_detail_text">{review.text}</div>
      </div>
    </div>
  );
};

export default ReviewDetail;
