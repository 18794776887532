import React, { createContext, useContext, useEffect, useReducer } from "react";
import { getLocalStorage, getToursCountInFav } from "../helper/function";
export const FavContext = createContext();
export const useFav = () => useContext(FavContext);

const FavListContextProvider = ({ children }) => {
  const INIT_STATE = {
    fav: JSON.parse(localStorage.getItem("fav")) || { tours: [] },
    favLength: getToursCountInFav(),
  };

  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case "GET_FAV":
        return { ...state, fav: action.payload };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  // getFav
  const getFav = () => {
    let fav = getLocalStorage() || { tours: [] };
    if (!fav.tours) {
      fav.tours = [];
    }
    dispatch({
      type: "GET_FAV",
      payload: fav,
    });
  };
  // getFav

  // Adding tout to favList
  const AddTourToFav = (tour) => {
    let fav = getLocalStorage() || { tours: [] };
    if (!fav.tours) {
      fav.tours = [];
    }
    let newFav = {
      item: tour,
      count: 1,
    };
    let FavToFind = fav.tours.filter((elem) => elem.item.id === tour.id);
    if (FavToFind.length === 0) {
      fav.tours.push(newFav);
    } else {
      fav.tours = fav.tours.filter((elem) => elem.item.id !== tour.id);
    }
    localStorage.setItem("fav", JSON.stringify(fav));
    dispatch({
      type: "GET_FAV",
      payload: fav,
    });
  };
  // Adding tout to favList

  // Проверка братская на присутствие lmao
  const checkTourInFav = (id) => {
    let fav = getLocalStorage() || { tours: [] };
    if (fav.tours) {
      let newFav = fav.tours.filter((elem) => elem.item.id === id);
      return newFav.length > 0;
    }
    return false;
  };

  // Проверка братская на присутствие lmao

  useEffect(() => {
    getFav();
  }, []);

  // удаление
  const deleteTourFromFav = (id) => {
    let fav = getLocalStorage() || { tours: [] };
    fav.tours = fav.tours.filter((elem) => elem.item.id !== id);
    localStorage.setItem("fav", JSON.stringify(fav));
    dispatch({
      type: "GET_FAV",
      payload: fav,
    });
  };
  // удаление

  const values = {
    checkTourInFav,
    AddTourToFav,
    fav: state.fav,
    getFav,
    deleteTourFromFav,
  };
  return <FavContext.Provider value={values}>{children}</FavContext.Provider>;
};

export default FavListContextProvider;
