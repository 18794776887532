import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { API } from "../helper/const";

const productContext = createContext();
export const useProduct = () => useContext(productContext);

const INIT_STATE = {
  tours: [],
  cars: [],
  totalPages: 0,
};

const ProductContextProvider = ({ children }) => {
  const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case "GET_TOURS":
        return {
          ...state,
          tours: action.payload.tours,
          totalPages: action.payload.totalPages,
        };
      case "GET_CARS":
        return { ...state, cars: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  // get tours
  const getTours = useCallback(async () => {
    try {
      let allTours = [];
      let nextPageUrl = `${API}/tour/tour_list/?page=1`;

      while (nextPageUrl) {
        const { data } = await axios.get(nextPageUrl);
        allTours = [...allTours, ...data.results];
        nextPageUrl = data.next;
      }

      dispatch({
        type: "GET_TOURS",
        payload: {
          tours: allTours,
        },
      });
    } catch (error) {
      console.error("Ошибка при получении всех туров:", error);
    }
  }, [dispatch]);

  // get cars
  const getCars = useCallback(async () => {
    try {
      const { data } = await axios.get(`${API}/tour/car_list/`);
      dispatch({
        type: "GET_CARS",
        payload: data.results,
      });
    } catch (error) {
      console.error("Ошибка при получении машин:", error);
    }
  }, [dispatch]);

  const values = {
    getTours,
    tours: state.tours,
    totalPages: state.totalPages,
    getCars,
    cars: state.cars,
  };

  return (
    <productContext.Provider value={values}>{children}</productContext.Provider>
  );
};

export default ProductContextProvider;
